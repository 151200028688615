<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('selectedTenureType') }">
    <div class="left--col">
      <label for="tenure-type-field">
        <span>{{ $t('addListing.tenure') }}</span>
        <span class="required">*</span>
        <img src="@/assets/img/icons/info.svg" class="info--icon" id="tenure-hint-section" />
        <PopOverListingForm
          target="tenure-hint-section"
          :content="$t('addListing.hint.tenureType')"
        />
      </label>
    </div>
    <div class="right--col">
      <multiselect
        name="tenureType"
        id="tenure-type-field"
        v-model="selectedTenureType"
        :options="tenureTypeList"
        track-by="id"
        label="name"
        :searchable="false"
        :allowEmpty="false"
        :close-on-select="true"
        :class="validation.hasError('selectedTenureType') ? 'is-invalid' : ''"
        :placeholder="$t('addListing.placeholder.tenure')"
      ></multiselect>
      <span class="val-error" v-if="validation.hasError('selectedTenureType')">{{
        validation.firstError('selectedTenureType')
      }}</span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';
import { Validator } from 'simple-vue-validator';

export default {
  name: 'tenure-type-section',
  components: {
    PopOverListingForm,
    Multiselect,
  },
  computed: {
    allowTenure() {
      return this.$store.getters['v2/listingForm/sect1/basicInfo/allowTenure'];
    },
    tenureTypeList() {
      return this.$store.state.v2.listingForm.sect1.basicInfo.tenureTypeList;
    },
    selectedTenureType: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.selectedTenureType;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SELECTED_TENURE_TYPE', value);
      },
    },
  },
  validators: {
    selectedTenureType(value) {
      if (!this.allowTenure) return null;
      return Validator.value(value).required(this.$i18n.t('errors.tenure_type.required'));
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
